<template>
  <div id="data-list-list-view" class="data-list-container">
    <brand-add
      :isSidebarActive="addNewDataSidebar"
      @closeSidebar="toggleDataSidebar"
      :data="sidebarData"
    />
    <div id="ecommerce-wishlist-demo">
      <div class="items-grid-view vx-row match-height" appear>
        <template v-if="brands.length">
          <div
            class="vx-col lg:w-1/4 md:w-1/3 sm:w-1/2 w-full"
            v-for="item in brands"
            :key="item.id"
          >
            <item-grid-view :item="item" @click="brandVisitorsDetails(item.id)">
              <!-- SLOT: ACTION BUTTONS -->
              <template slot="action-buttons">
                <div class="flex flex-wrap">
                  <!-- PRIMARY BUTTON : REMOVE -->

                  <!-- SECONDARY BUTTON: MOVE TO CART -->

                  <div
                    class="
                      item-view-secondary-action-btn
                      bg-primary
                      p-3
                      flex flex-grow
                      items-center
                      justify-center
                      text-white
                      cursor-pointer
                    "
                  >
                    <span class="text-sm font-semibold ml-2">
                      عرض الزيارات</span
                    >
                  </div>
                </div>
              </template>
            </item-grid-view>
            <vs-popup
              class="holamundo"
              :title="'تعديل نسبة الزيادة للعلامة التجارية ' + brand.name"
              :active.sync="popupActive"
            >
              <div class="flex flex-col justify-items-center">
                <span class="text-danger"
                  >ملاحظة: عند تعديل النسبة سيتم تعديل اسعار جميع المنتجات
                  التابعة للعلامة التجارية</span
                >
                <div class="vx-col w-1/2 w-full mb-2 mt-10 flex align-center">
                  <vs-input
                    type="number"
                    size="large"
                    class="w-full"
                    aria-placeholder="test"
                    max="100"
                    v-model="increase_percentage"
                  />
                </div>
                <div class="mb-6">&#8203;</div>
                <vs-button
                  @click="editBrandPercentage()"
                  color="primary"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-plus"
                >
                  تعديل</vs-button
                >
              </div>
            </vs-popup>
          </div>
        </template>
        <EmptyState
          :link="addNewData"
          page="العلامات التجارية"
          :display="true"
          v-else
        ></EmptyState>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyState from "@/views/emptyState.vue";
import moduleBrand from "@/store/brands/moduleBrand.js";
const ItemGridView = () => import("./components/brand-list.vue");

export default {
  components: {
    ItemGridView,
    EmptyState,
  },
  data() {
    return {
      itemsPerPage: 5,
      isMounted: false,
      selected_id: 0,
      popupActive: false,
      brand: {},
      increase_percentage: "",
      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
    };
  },
  computed: {
    brands() {
      return this.$store.state.brand.brands;
    },
  },
  methods: {
    addNewData() {
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    editPercentage(item) {
      this.brand = item;
      this.popupActive = true;
    },
    brandVisitorsDetails(id) {
      this.$router.push({ path: '/brand-visitors-details', query: { id } });
    },
    editBrandPercentage() {
      var bodyFormData = new FormData();
      bodyFormData.set("id", this.brand.id);
      let increase = this.increase_percentage / 100 + 1;
      bodyFormData.set("increase_percentage", increase);
      this.$store
        .dispatch("brand/updatePercentage", bodyFormData)
        .then((response) => {
          this.popupActive = false;
          this.successDialog(response.data.message);
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.errorDialog(error);
          this.$vs.loading.close();
        });
    },
    editData(data) {
      this.sidebarData = data;
      this.toggleDataSidebar(true);
    },
    getData() {
      this.$vs.loading();

      this.$store
        .dispatch("brand/fetchBrands")
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    openConfirmArchive(id) {
      this.selected_id = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "تأكيد",
        text: ` هل انت متأكد من ارشفة هذه العلامة التجارية؟`,
        accept: this.archiveData,
        acceptText: "نعم",
        cancelText: "الغاء",
      });
    },
    archiveData() {
      this.$store
        .dispatch("brand/archiveBrand", this.selected_id)
        .then((response) => {
          this.successDialog(response.data.message);
        })
        .catch((error) => {
          this.errorDialog(error);
        });
    },
  },
  created() {
    if (!moduleBrand.isRegistered) {
      this.$store.registerModule("brand", moduleBrand);
      moduleBrand.isRegistered = true;
    }
    this.getData();
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>
